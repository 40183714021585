/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #000000;
  color: #fff;
  position: relative;
  overflow-x: hidden;
}

#animation-container {
  position: fixed;
  height: 100%;
  width: 100%;
}

a {
  color: #18d26e;
  text-decoration: none;
}

a:hover {
  color: #35e888;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: ease-in-out 0.3s;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 997;
  overflow-y: auto;
}

#header * {
  transition: ease-in-out 0.3s;
}

#header h1 {
  font-size: 48px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-shadow: 2px 2px 3px #000000;
}

#header h1 a,
#header h1 a:hover {
  color: #fff;
  line-height: 1;
  display: inline-block;
}

#header h2 {
  font-size: 24px;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 2px 2px 3px #000000;
}

#header h2 span {
  color: #fff;
  border-bottom: 2px solid #18d26e;
  padding-bottom: 4px;
}

#header img {
  padding: 0;
  margin: 0;
}

#header .social-links {
  margin-top: 40px;
  display: flex;
}

#header .social-links a {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  margin-right: 8px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#header .social-links a:hover {
  background: #18d26e;
}

@media (max-width: 992px) {
  #header h1 {
    font-size: 40px;
  }

  #header h2:first-of-type {
    margin-top: 5rem;
  }

  #header h2 {
    font-size: 21px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  #header h2:last-of-type {
    margin-bottom: 5rem;
  }

  #header .social-links {
    margin-top: 0;
  }

  #header .social-links a {
    background: rgba(0, 0, 0, 0.2);
  }

  #header .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* Header Top */
#header.header-top {
  height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}

#header.header-top .social-links,
#header.header-top h2 {
  display: none;
}

#header.header-top h1 {
  margin-right: auto;
  font-size: 36px;
}

#header.header-top img {
  max-height: 60px;
  margin-right: 20px !important;
}

#header.header-top .container {
  display: flex;
  align-items: center;
}

#header.header-top .navbar {
  margin: 0;
}

@media (max-width: 768px) {
  #header.header-top {
    height: 60px;
  }

  #header.header-top h1 {
    font-size: 26px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
  margin-top: 35px;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar li + li {
  margin-left: 30px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
  text-shadow: 2px 2px 3px #000000;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #18d26e;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 25px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  position: fixed;
  right: 15px;
  top: 15px;
}

@media (max-width: 992px) {
  .navbar {
    margin-top: 0;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
  margin-top: 0;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 45px;
  left: 15px;
  padding: 10px 0;
  overflow-y: auto;
  transition: 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.navbar-mobile li {
  padding: 12px 20px;
}

.navbar-mobile li + li {
  margin: 0;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  font-size: 16px;
  position: relative;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 140px;
  bottom: 100%;
  opacity: 0;
  transition: ease-in-out 0.4s;
  z-index: 2;
}

section.section-show {
  top: 100px;
  bottom: auto;
  opacity: 1;
  padding-bottom: 45px;
}

section .container {
  background: rgba(0, 0, 0, 0.9);
  padding: 30px;
}

@media (max-width: 768px) {
  section {
    top: 120px;
  }

  section.section-show {
    top: 80px;
  }
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 20px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #4ceb95;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: -15px 0 15px 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-me .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #18d26e;
}

.about-me .content ul {
  list-style: none;
  padding: 0;
}

@media (max-width: 992px) {
  .about-me .content .row div:first-of-type ul {
    margin-bottom: 0;
  }
}

.about-me .content ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.about-me .content ul strong {
  margin-right: 10px;
}

.about-me .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #18d26e;
  line-height: 0;
}

.about-me .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: rgba(255, 255, 255, 0.08);
}

.counts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: rgba(255, 255, 255, 0.1);
  padding: 12px;
  color: #18d26e;
  border-radius: 50px;
  line-height: 0;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #fff;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Interests
--------------------------------------------------------------*/
.interests .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.09);
  transition: ease-in-out 0.3s;
}

.interests .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}

.interests .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
  color: #fff;
}

.interests .icon-box:hover {
  background: rgba(255, 255, 255, 0.12);
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid rgba(255, 255, 255, 0.12);
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.25);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #18d26e;
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/
.resume .resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.resume .resume-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #18d26e;
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #18d26e;
  border: 2px solid #18d26e;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  background: rgba(204, 204, 204, 0.1);
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #18d26e;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #fff;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  background: #18d26e;
  border-color: #18d26e;
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #18d26e;
}

.services .icon-box:hover .icon::before {
  background: #35e888;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 15px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  margin: 0 3px 10px 3px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #18d26e;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #63eda3;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}

.portfolio-details .container {
  padding-top: 20px;
  padding-bottom: 40px;
}

.portfolio-details .portfolio-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-info {
  padding-top: 45px;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-info p {
  font-size: 15px;
  padding: 15px 0 0 0;
}

@media (max-width: 992px) {
  .portfolio-details .portfolio-info {
    padding-top: 20px;
  }
}

.portfolio-details .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}

.portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #18d26e;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  padding: 20px;
  width: 100%;
  background: rgba(255, 255, 255, 0.08);
}

.contact .info-box i.bx {
  font-size: 24px;
  color: #18d26e;
  border-radius: 50%;
  padding: 14px;
  margin-bottom: 30px;
  float: left;
  background: rgba(255, 255, 255, 0.1);
}

.contact .info-box h3 {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 700;
  margin: 15px 0 8px 68px;
}

.contact .info-box p {
  padding: 0;
  color: #fff;
  line-height: 24px;
  font-size: 14px;
  margin: 0 0 0 68px;
}

.contact .info-box .social-links {
  margin: 5px 0 0 68px;
  display: flex;
}

.contact .info-box .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  margin-right: 12px;
  transition: 0.3s;
}

.contact .info-box .social-links a:hover {
  color: #18d26e;
}

.contact .php-email-form {
  padding: 30px;
  background: rgba(255, 255, 255, 0.08);
}

.contact .php-email-form .error-message {
  display: none;
  background: rgba(255, 255, 255, 0.08);
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  background: rgba(255, 255, 255, 0.08);
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: rgba(255, 255, 255, 0.08);
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.08);
  border: 0;
  transition: 0.3s;
  color: #fff;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  background-color: rgba(255, 255, 255, 0.11);
}

.contact .php-email-form input::-moz-placeholder,
.contact .php-email-form textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
  opacity: 1;
}

.contact .php-email-form input::placeholder,
.contact .php-email-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.3);
  opacity: 1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #18d26e;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #15bb62;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Credits
--------------------------------------------------------------*/
.credits {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 15px;
  text-align: right;
  font-size: 13px;
  color: #fff;
  z-index: 999999;
}

@media (max-width: 992px) {
  .credits {
    text-align: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
  }
}

.credits a {
  color: #18d26e;
  transition: 0.3s;
}

.credits a:hover {
  color: #fff;
}

/*--------------------------------------------------------------
# Simple Icons (https://icon-sets.iconify.design/simple-icons)
--------------------------------------------------------------*/
.simple-icons {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: currentColor;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.simple-icons.indeed {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M11.566 21.563v-8.762c.255.023.5.035.758.035c1.223 0 2.374-.32 3.35-.893v9.618c0 .822-.195 1.429-.575 1.834c-.378.403-.88.605-1.491.605c-.6 0-1.077-.202-1.468-.618c-.378-.403-.574-1.01-.574-1.819M11.589.566c2.545-.893 5.442-.845 7.619.987c.405.369.867.833 1.051 1.38c.22.692-.77-.073-.906-.167c-.71-.453-1.418-.833-2.212-1.094C12.86.387 8.812 2.709 6.295 6.315c-1.052 1.594-1.737 3.272-2.3 5.117c-.06.202-.109.465-.22.642c-.112.203-.048-.546-.048-.57c.084-.763.244-1.5.441-2.237C5.33 5.337 7.897 2.066 11.59.566m4.928 7.059a3.02 3.02 0 1 1-6.04 0a3.02 3.02 0 1 1 6.04 0'/%3E%3C/svg%3E");
  transform: translate(-0.05em, 0);
}
